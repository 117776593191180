<template>
  <register-form/>
</template>

<script>
import RegisterForm from "@/components/shared/Register";

export default {
  name: "RegisterPage",
  components: {RegisterForm}
}
</script>

<style scoped>

</style>