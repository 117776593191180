<template>
  <v-list disabled flat dense>
    <v-list-item>
      <v-list-item-icon>
        <v-icon :color="validPasswordLength ? 'success':'red'">
          mdi-{{ validPasswordLength ? 'check' : 'close' }}-circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>It contains at least 8 characters and at most 20 characters.</v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon :color="hasAtLeastOneDigit ? 'success':'red'">
          mdi-{{ hasAtLeastOneDigit ? 'check' : 'close' }}-circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>It contains at least one digit.</v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon :color="hasAtLeastOneUppercaseAlphabet ? 'success':'red'">
          mdi-{{ hasAtLeastOneUppercaseAlphabet ? 'check' : 'close' }}-circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>It contains at least one upper case alphabet.</v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon :color="hasAtLeastOneLowercaseAlphabet ? 'success':'red'">
          mdi-{{ hasAtLeastOneLowercaseAlphabet ? 'check' : 'close' }}-circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>It contains at least one lower case alphabet.</v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon :color="hasAtLeastOneSpecialCharacter ? 'success':'red'">
          mdi-{{ hasAtLeastOneSpecialCharacter ? 'check' : 'close' }}-circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>It contains at least one special character which includes !@$*()-+=^
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon :color="hasNoInvalidSpecialCharacters ? 'success':'red'">
          mdi-{{ hasNoInvalidSpecialCharacters ? 'check' : 'close' }}-circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>It does not contain a special character which includes #&?
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon :color="hasNoWhitespace ? 'success':'red'">mdi-{{ hasNoWhitespace ? 'check' : 'close' }}-circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>It doesn’t contain any white space.</v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>

export default {
  name: "PasswordComplexityChecker",
  props: {
    value: {}
  },
  computed: {
    validPasswordLength() {
      let localRegex = /^.{8,20}$/;
      return localRegex.test(this.value)
    },
    hasAtLeastOneDigit() {
      let localRegex = /^(?=.*[0-9])/;
      return localRegex.test(this.value)
    },
    hasAtLeastOneLowercaseAlphabet() {
      let localRegex = /^(?=.*[a-z])/;
      return localRegex.test(this.value)
    },
    hasAtLeastOneUppercaseAlphabet() {
      let localRegex = /^(?=.*[A-Z])/;
      return localRegex.test(this.value)
    },
    hasAtLeastOneSpecialCharacter() {
      let localRegex = /^(?=.*[!@$^&-+=()])/;
      return localRegex.test(this.value)
    },
    hasNoInvalidSpecialCharacters() {
      let localRegex = /^(?=.*[#&?])/;
      return !localRegex.test(this.value)
    },
    hasNoWhitespace() {
      let localRegex = /\s/;
      return !localRegex.test(this.value)
    },
    isValidPassword() {
      return (this.validPasswordLength &&
          this.hasAtLeastOneDigit &&
          this.hasAtLeastOneLowercaseAlphabet &&
          this.hasAtLeastOneUppercaseAlphabet &&
          this.hasAtLeastOneSpecialCharacter &&
          this.hasNoWhitespace && this.hasNoInvalidSpecialCharacters) ? true : false
    }
  },
  watch: {
    isValidPassword(newVal) {
        this.$emit('isValidPassword',newVal)
    }
  }
}
</script>

<style scoped>

</style>