<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">{{ config.product.name }}</div>
      <h2 class="text-h3 text-lg-h2 mb-6">Sign up</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-card-text v-if="formSubmit">
        <h4>What happens next?</h4>
        <v-timeline
            align-top
            dense
        >
          <v-timeline-item
              icon="mdi-check"
              color="success"
              small
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>You</strong>
              </v-col>
              <v-col>
                <strong>Registration.</strong>
                <div class="text-caption">
                  Your user account has been created.
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item
              icon="mdi-numeric-circle-2-outline"
              color="pink"
              small
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>Group Admin</strong>
              </v-col>
              <v-col>
                <strong>Account Activation</strong>
                <div class="text-caption">
                  Account is activated
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item
              icon="mdi-numeric-circle-3-outline"
              color="pink"
              small
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>Site Admin</strong>
              </v-col>
              <v-col>
                <strong>Email notification</strong>
                <div class="text-caption">
                  Email notification that your account is ready for use is sent out.
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item
              icon="mdi-numeric-circle-3-outline"
              color="pink"
              small
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>You</strong>
              </v-col>
              <v-col>
                <strong>First Login</strong>
                <div class="text-caption">
                  The Account is ready for you to login and use.
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
        <v-btn block class="secondary" x-large :loading="isLoading"
               @click="$router.push('/')"><v-icon>mdi-home</v-icon>Home
        </v-btn>
      </v-card-text>
      <v-card-text v-if="!formSubmit">
        <div class="d-flex flex-column flex-md-row">
          <v-text-field required label="First Name" outlined class="mr-md-2" v-model="firstname"></v-text-field>
          <v-text-field required label="Last Name" outlined v-model="lastname"></v-text-field>
        </div>
        <v-text-field required label="Email" outlined v-model="username"></v-text-field>
        <v-text-field required label="Password" outlined hide-details
                      v-model="password"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"></v-text-field>
        <!--      <v-checkbox dense label="Remember me"></v-checkbox>-->
        <password-complexity-checker v-model="password" v-on:isValidPassword="validatePassword($event)"/>
        <group-picker v-model="group"/>
        <v-btn block class="secondary" x-large :loading="isLoading" :disabled="isLoading || !formComplete"
               @click="register">Register
        </v-btn>
      </v-card-text>
    </v-card>
    <div class="text-overline text-uppercase mt-3 text-center">
      By signing in, you agree to the
      <a href="/terms">Terms of Service</a> & <a href="/privacy">Privacy Policy</a>
    </div>
  </v-container>
</template>

<script>
import config from '@/configs'
import {mapMutations, mapActions, mapState} from "vuex";
import PasswordComplexityChecker from "@/components/shared/PasswordComplexityChecker";
import {UserHttp} from "@/http/UserHttp";
import GroupPicker from "@/components/shared/GroupPicker";

export default {
  name: "RegisterForm",
  components: {GroupPicker, PasswordComplexityChecker},
  data() {
    return {
      config,
      firstname: '',
      lastname: '',
      username: '',
      password: '',
      showPassword:false,
      group: null,
      validPassword: false,
      isLoading: false,
      formSubmit: false,
    }
  },
  computed: {
    ...mapState("app", ["loggedInUser"]),
    formComplete() {
      return this.firstname && this.lastname && this.username && this.password.length >= 8 && this.validPassword && this.group && this.validateEmail
    },
    validateEmail() {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.username)) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions("group", ["setGroups"]),
    ...mapMutations("app", ["setToast", "setLoggedIn", "setToken", "setLoggedInUser"]),
    ...mapActions("user", ["setAccountRoles", "setFlyingStatus"]),
    validatePassword(val) {
      this.validPassword = val
    },

    async register() {
      if (this.username.length > 0 && this.password.length > 0) {
        this.isLoading = true;
        try {
          const person = {
            username: this.username.toLowerCase(),
            name: this.firstname + ' ' + this.lastname,
            password:this.password,
            email: this.username,
            group: this.group
          }
          await UserHttp.createAccount(person)
          this.firstname = null,
          this.lastname = null,
          this.username = null,
          this.password = null,
          this.group = null
          this.formSubmit = true
          this.setToast({color: 'success', text: 'New user account created successfully', show: true});
        } catch (e) {
          if (e.response) {
            this.setToast({color: 'error', text: e.response.data, show: true})
          } else {
            this.setToast({color: 'error', text: e.message, show: true})
          }
        } finally {
          this.isLoading = false;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>